body {
  margin: 0;
}

.fileContainer .deleteImage {
  background: #c92028 !important;
  padding: 8px;
  box-sizing: border-box;
  line-height: 16px !important;
  font-size: 18px !important;
}

.fileContainer .chooseFileButton {
  min-width: 144px !important;
  height: 40px !important;
  font-size: 18px !important;
  line-height: 32px !important;
  margin: 16px 0px !important;
  border-radius: 4px !important;
  background: none !important;
  color: #c92028 !important;
  border: 1px solid rgba(201, 32, 40, 0.5) !important;
}

.fileContainer .uploadPictureContainer {
  width: auto !important;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}
